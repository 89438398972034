<template>
    <div class="baseMain">
        <div class="baseSearch clearfloat">
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>组织部门：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-cascader v-model="searchForm.org_id" :options="org_id_list" :props="props"
                                 clearable @change="search"></el-cascader>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>缴费项目：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-select v-model="searchForm.project_id" clearable @change="search">
                        <el-option v-for="item in project_id_list" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
            <div v-if="activeName === '1'" class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>支付方式：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-select v-model="searchForm.pay_type" clearable @change="search">
                        <el-option v-for="item in pay_type_list" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
            <div v-if="activeName === '1'" class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>支付状态：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-select v-model="searchForm.pay_status" clearable @change="search">
                        <el-option v-for="item in pay_status_list" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
            <div v-if="activeName === '2'" class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>退款状态：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-select v-model="searchForm.refund_status" clearable @change="search">
                        <el-option v-for="item in refund_status_list" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>学工号：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.card_number" placeholder="请输入内容" clearable @change="search"></el-input>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>姓名：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.user_name" placeholder="请输入内容" clearable @change="search"></el-input>
                </div>
            </div>
            <div v-if="activeName === '1'" class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>交易单号：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.transaction_id" placeholder="请输入内容" clearable @change="search"></el-input>
                </div>
            </div>
            <div v-if="activeName === '1'" class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>交易时间：</span>
                </div>
                <div class="baseSearchOneRight2">
                    <el-date-picker v-model="searchForm.pay_time" type="daterange" unlink-panels
                                    range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
                                    value-format="YYYY-MM-DD" @change="search"></el-date-picker>
                </div>
            </div>
            <div v-if="activeName === '2'" class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>退款日期：</span>
                </div>
                <div class="baseSearchOneRight2">
                    <el-date-picker v-model="searchForm.refund_time" type="daterange" unlink-panels
                                    range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
                                    value-format="YYYY-MM-DD" @change="search"></el-date-picker>
                </div>
            </div>
            <div class="baseSearchOne">
                <el-button type="primary" style="margin-left: 20px;" @click="search">搜索</el-button>
                <el-button @click="cleanAll">清空</el-button>
            </div>
        </div>
        <div class="baseOperate clearfloat">
            <div class="baseOperateOne">
                <el-button type="primary" @click="openExport" v-if="authorityStatus.projectExport.show">导出全部</el-button>
            </div>
            <div v-if="activeName === '3'" class="baseOperateOne tableButton">
                <el-button type="primary" @click="openCall(3)">全部发送催缴通知</el-button>
            </div>
            <div v-if="activeName === '3'" class="baseOperateOne tableButton">
                <el-button type="primary" @click="openCall(2)">选择部分发送催缴通知</el-button>
            </div>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="已缴查询" name="1">
                <div class="baseTable" v-loading="tableLoading">
                    <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData">
                        <el-table-column prop="order_no" label="业务订单号"></el-table-column>
                        <el-table-column prop="transaction_id" label="交易单号"></el-table-column>
                        <el-table-column prop="card_number" label="学工号"></el-table-column>
                        <el-table-column prop="user_name" label="姓名"></el-table-column>
                        <el-table-column prop="class_name" label="组织部门"></el-table-column>
                        <el-table-column prop="project_name" label="缴费项目"></el-table-column>
                        <el-table-column prop="y_price" label="应缴金额"></el-table-column>
                        <el-table-column prop="pay_price" label="缴费金额"></el-table-column>
                        <el-table-column prop="pay_type_name" label="支付方式"></el-table-column>
                        <el-table-column prop="pay_status_name" label="支付状态"></el-table-column>
                        <el-table-column prop="pay_time" label="交易时间" width="110"></el-table-column>
                        <!--<el-table-column prop="name" label="订单备注"></el-table-column>-->
                        <el-table-column label="操作">
                            <template #default="scope">
                                <el-link class="tableButton" type="danger" @click.prevent="openRefund(scope.row)"
                                        v-if="scope.row.refund_price < (scope.row.pay_price * 1) && authorityStatus.projectRefund.show">
                                    退款
                                </el-link>
                                <el-link class="tableButton" type="primary"
                                        @click.prevent="openReceipt(scope.row)" v-if="authorityStatus.projectDown.show">
                                    下载收据
                                </el-link>
                            </template>
                        </el-table-column>
                    </publicTable>
                </div>
            </el-tab-pane>
            <el-tab-pane label="退款查询" name="2">
                <div class="baseTable" v-loading="tableLoading">
                    <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData">
                        <el-table-column prop="r_order_no" label="业务订单号"></el-table-column>
                        <el-table-column prop="transaction_id" label="交易单号"></el-table-column>
                        <el-table-column prop="card_number" label="学工号"></el-table-column>
                        <el-table-column prop="user_name" label="姓名"></el-table-column>
                        <el-table-column prop="org_name" label="组织部门"></el-table-column>
                        <el-table-column prop="project_name" label="缴费项目"></el-table-column>
                        <el-table-column prop="price" label="缴费金额"></el-table-column>
                        <el-table-column prop="refund_price" label="退款金额"></el-table-column>
                        <el-table-column prop="refund_type_name" label="退款方式"></el-table-column>
                        <el-table-column prop="refund_status_name" label="退款状态"></el-table-column>
                        <el-table-column prop="remarks" label="退款备注"></el-table-column>
                        <el-table-column prop="updated_at" label="退款时间" width="110"></el-table-column>
                        <el-table-column prop="op_name" label="申请人"></el-table-column>
                        <el-table-column label="操作">
                            <template #default="scope">
                                <!--<el-link class="tableButton" type="danger" @click.prevent="openRefund(scope.row)">-->
                                <!--重新申请-->
                                <!--</el-link>-->
                                <el-link class="tableButton" type="primary" @click.prevent="openReceipt2(scope.row)"
                                        v-if="scope.row.refund_status == 1 && authorityStatus.refundDown.show">
                                    下载凭证
                                </el-link>
                            </template>
                        </el-table-column>
                    </publicTable>
                </div>
            </el-tab-pane>
            <el-tab-pane label="代缴查询" name="3">
                <div class="baseTable" v-loading="tableLoading">
                    <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData"
                                :publicTableConfigure="{selection:true}" @selAllDataFun="getSelData($event)">
                        <el-table-column prop="project_name" label="缴费项目"></el-table-column>
                        <el-table-column prop="card_number" label="学工号"></el-table-column>
                        <el-table-column prop="user_name" label="姓名"></el-table-column>
                        <el-table-column prop="class_name" label="组织部门"></el-table-column>
                        <el-table-column prop="price" label="应缴金额"></el-table-column>
                        <el-table-column prop="be_received_price" label="待缴金额"></el-table-column>
                        <el-table-column label="操作">
                            <template #default="scope">
                                <el-link class="tableButton" type="primary" @click.prevent="openMoneyInput(scope.row)"
                                        v-if="authorityStatus.arrearsMoney.show">
                                    现金收费录入
                                </el-link>
                                <el-link class="tableButton" type="primary" @click.prevent="openCall(1,scope.row)">
                                    发送催缴通知
                                </el-link>
                            </template>
                        </el-table-column>
                    </publicTable>
                </div>
            </el-tab-pane>
        </el-tabs>
        <div class="basePage" style="margin-top: 4px;">
            <publicPage ref="publicPageUserStudent" :publicPageTotal="{total:total}"
                        @pageChange="getTableData"></publicPage>
        </div>
        <el-dialog title="退款" v-model="dialogVisible" width="600px">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px">
                <el-form-item label="学工号">
                    {{selMsg.card_number}}
                </el-form-item>
                <el-form-item label="姓名">
                    {{selMsg.user_name}}
                </el-form-item>
                <el-form-item label="项目名称">
                    {{selMsg.project_name}}
                </el-form-item>
                <el-form-item label="支付方式">
                    {{selMsg.pay_type_name}}
                </el-form-item>
                <el-form-item label="业务订单号">
                    {{selMsg.order_no}}
                </el-form-item>
                <el-form-item label="缴费金额">
                    {{selMsg.pay_price}}
                </el-form-item>
                <el-form-item label="退款方式" prop="refund_type">
                    <el-radio-group v-model="ruleForm.refund_type">
                        <el-radio label="1" v-if="selMsg.pay_type == 1">微信</el-radio>
                        <el-radio label="2">现金</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="退款金额">
                    <el-input-number v-model="ruleForm.refund_price" controls-position="right"
                                     :min="0" :max="selMsg.pay_price*1"></el-input-number>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input type="textarea" v-model="ruleForm.remarks" :autosize="{ minRows: 2 }"
                              show-word-limit maxlength="100"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="saveRefund">确 定</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog title="下载收据" v-model="receiptVisible" width="1600px">
            <div class="receipt" ref="imageTofile">
                <div class="receiptTitle">
                    <span>{{showMsg.school_name}}缴费电子收据</span>
                </div>
                <div class="receiptSubtitle">
                    <div class="receiptSubtitleLeft">
                        <span>业务订单号：{{selMsg.order_no}}</span>
                    </div>
                    <div class="receiptSubtitleRight">
                        <span>交易时间：{{selMsg.pay_time}}</span>
                    </div>
                </div>
                <div class="receiptMain receiptBT receiptBL clearfloat">
                    <div class="receiptMainLeft">
                        <div class="receiptMainLeftOne receiptBR receiptBB">
                            <div class="receiptMainLeftMsg">
                                <div class="receiptMainLeftMsgTop">
                                    <span>姓名</span>
                                </div>
                                <div class="receiptMainLeftMsgBottom">
                                    <span>{{selMsg.user_name}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="receiptMainLeftOne clearfloat">
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>学工号</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>{{selMsg.card_number}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>支付方式</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>{{selMsg.pay_type_name}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="receiptMainLeftOne clearfloat">
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>组织机构</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>{{selMsg.class_name}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>学年学期</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>无</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="receiptMainLeft">
                        <div class="receiptMainLeftOne2 receiptBR receiptBB">
                            <div class="receiptMainLeftMsg">
                                <div class="receiptMainLeftMsgTop" style="padding-top: 30px;">
                                    <span>缴费项目</span>
                                </div>
                                <div class="receiptMainLeftMsgBottom" style="padding-top: 10px;">
                                    <span>{{selMsg.project_name}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="receiptMainLeftOne clearfloat">
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>应收总金额</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>￥{{selMsg.y_price}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>实收金额</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>￥{{selMsg.pay_price}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="receiptMainLeftOne3 receiptBL receiptBR receiptBB">
                    <span>人民币（大写）：{{selMsg.daxie}}</span>
                </div>
                <div class="receiptMainLeftOne3">
                    <span>收款单位（盖章）：{{showMsg.school_name}}</span>
                    <span style="float: right;">收款人：{{showMsg.school_name}}</span>
                </div>
                <div class="receiptMainLeftOne3">
                    <span>注：本电子收据仅作为线上缴费确认依据，不作为报销凭证</span>
                </div>
                <!--crossorigin="anonymous"-->
                <img class="receiptMainZhang" :src="showMsg.receipt_seal">
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="downImage">下 载</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog title="现金收费录入" v-model="moneyInputShow" width="600px">
            <el-form :model="ruleForm2" :rules="rules" ref="ruleForm2" label-width="200px">
                <el-form-item label="学工号">
                    {{selMsg.card_number}}
                </el-form-item>
                <el-form-item label="姓名">
                    {{selMsg.user_name}}
                </el-form-item>
                <el-form-item label="项目名称">
                    {{selMsg.project_name}}
                </el-form-item>
                <el-form-item label="待缴金额">
                    {{selMsg.be_received_price}}
                </el-form-item>
                <el-form-item label="收费金额">
                    <el-input-number v-model="ruleForm2.pay_price" controls-position="right" :min="0"></el-input-number>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="saveMoneyInput">确 定</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>

    import publicPage from '../../components/publicPage.vue'

    import publicTable from '../../components/publicTable.vue'

    import XLSX from 'xlsx'

    import html2canvas from 'html2canvas'

    import {businessGet, businessPost} from '../../api/business.js'

    import Global from '../../Global.js'

    import {utilsGetOptionsList, utilsGetAuthority, utilsExportExcel, utilsDX} from '../../utils/utils.js'

    import {ElLoading} from 'element-plus'

    export default {
        name: "payPaid",
        components: {
            publicPage,
            publicTable
        },
        data() {
            return {
                activeName: '1',
                org_id_list: [],
                props: {
                    value: 'id',
                    label: 'name',
                    children: 'son',
                    checkStrictly: true
                },
                project_id_list: [],
                pay_type_list: [],
                pay_status_list: [],
                sort:"desc",
                searchForm: {
                    org_id: '',
                    project_id: '',
                    pay_type: '',
                    pay_status: '',
                    refund_status: '',
                    card_number: '',
                    user_name: '',
                    transaction_id:'',
                    pay_time: [],
                    refund_time: [],
                },
                searchHeight: 0,
                tableLoading: false,
                tableData: [],
                tableDataSel: [],
                total: 0,
                dialogVisible: false,
                moneyInputShow: false,
                ruleForm: {
                    pay_price: 0,
                    refund_type: '',
                    refund_price: 0,
                    remarks: '',
                },
                ruleForm2: {
                    pay_price: 0,
                },
                rules: {
                    name: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                    refund_type: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                },
                selMsg: null,
                receiptVisible: false,
                showMsg: {
                    school_name: '',
                    receipt_seal: '',
                },
                authorityStatus: {
                    projectRefund: {
                        method: 'post',
                        url: '/admin/v1/refund',
                        show: false,
                    },
                    projectDown: {
                        method: 'post',
                        url: 'dianzishouju',
                        show: false,
                    },
                    projectExport: {
                        method: 'get',
                        url: '/admin/v1/order_export',
                        show: false,
                    },
                    arrearsMoney: {
                        method: 'post',
                        url: '/admin/v1/cash_entry',
                        show: false,
                    },
                    arrearsExport: {
                        method: 'get',
                        url: '/admin/v1/arrears_export',
                        show: false,
                    },
                    refundDown: {
                        method: 'post',
                        url: 'tuikuanpingzheng',
                        show: false,
                    },
                    refundExport: {
                        method: 'get',
                        url: '/admin/v1/refund_export',
                        show: false,
                    }
                },
            }
        },
        mounted() {
            utilsGetAuthority(this.authorityStatus);
            this.userInfo = JSON.parse(localStorage.getItem(Global.GlobalLocalName2));
            this.showMsg.school_name = this.userInfo.school_name;
            this.getMsg();
            this.$nextTick(() => {
                let baseSearchDom = document.querySelector('.baseSearch');
                let baseOperateDom = document.querySelector('.baseOperate');
                let searchHeight = baseSearchDom.offsetHeight + baseOperateDom.offsetHeight;
                this.searchHeight = searchHeight+55;
                this.getTableData();
                let dom = document.querySelector('.el-cascader');
                dom.style.width = '100%';
            })
            this.get_project_id_list();
            this.pay_type_list = utilsGetOptionsList('Global_pay_type_obj')
            this.pay_status_list = utilsGetOptionsList('Global_pay_status_obj')
            this.refund_status_list = utilsGetOptionsList('Global_refund_status_obj');
            this.getTreeData();
        },
        methods: {
            openMoneyInput(val) {
                this.selMsg = val;
                this.ruleForm2.pay_price = 0;
                this.moneyInputShow = true;
            },
            saveMoneyInput() {
                this.$refs.ruleForm2.validate((valid) => {
                    if (valid) {
                        if (this.ruleForm2.pay_price > this.selMsg.be_received_price) {
                            this.$message({
                                showClose: true,
                                message: '录入金额不得大于待缴金额',
                                type: 'warning'
                            });
                            return
                        }
                        let url = '/admin/v1/cash_entry';
                        let data = {
                            receive_id: this.selMsg.id,
                            pay_price: this.ruleForm2.pay_price,
                        }
                        businessPost(url, data).then(res => {
                            if (res.data.status == 200) {
                                this.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'success'
                                });
                                this.activeName = '3'
                                this.moneyInputShow = false;
                                this.getTableData3();
                            }
                            else {
                                this.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'warning'
                                });
                            }
                        })
                    }
                })
            },
            getTreeData() {
                let url = '/admin/v1/organization?type=1';
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        this.org_id_list = res.data.data;
                    }
                })
            },
            get_project_id_list() {
                let url = '/admin/v1/projects?page=1&page_pre=500';
                businessGet(url).then(res => {

                    if (res.data.status == 200) {
                        let newArray = [];
                        res.data.data.list.forEach(item => {
                            let a = {
                                value: item.id,
                                label: item.name
                            }
                            newArray.push(a);
                        })
                        this.project_id_list = newArray;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            openExport() {
                if(this.activeName === '1') {
                    let url = `/admin/v1/order?is_export=1&sort=${this.sort}`;
                    for (let k in this.searchForm) {
                        if (this.searchForm[k]) {
                            if (k == 'org_id') {
                                url = url + '&' + k + '=' + this.searchForm[k][this.searchForm[k].length - 1];
                            }
                            else if (k == 'pay_time') {
                                if (this.searchForm[k][0] && this.searchForm[k][1]) {
                                    url = url + '&' + k + '=' + this.searchForm[k][0] + ' 00:00:00 - ' + this.searchForm[k][1] + ' 23:59:59';
                                }
                            }
                            else {
                                url = url + '&' + k + '=' + this.searchForm[k];
                            }
                        }
                    }
                    utilsExportExcel(url)
                } else if(this.activeName === '2') {
                    let url = '/admin/v1/refund?is_export=1';
                    for (let k in this.searchForm) {
                        if (this.searchForm[k]) {
                            if (k == 'org_id') {
                                url = url + '&' + k + '=' + this.searchForm[k][this.searchForm[k].length - 1];
                            }
                            else if (k == 'refund_time') {
                                if (this.searchForm[k][0] && this.searchForm[k][1]) {
                                    url = url + '&' + k + '=' + this.searchForm[k][0] + ' 00:00:00 - ' + this.searchForm[k][1] + ' 23:59:59';
                                }
                            }
                            else {
                                url = url + '&' + k + '=' + this.searchForm[k];
                            }
                        }
                    }
                    utilsExportExcel(url)
                }
            },
            base64ToBlob(code) {
                let parts = code.split(';base64,');
                let contentType = parts[0].split(':')[1];
                let raw = window.atob(parts[1]);
                let rawLength = raw.length;
                let uInt8Array = new Uint8Array(rawLength);
                for (let i = 0; i < rawLength; ++i) {
                    uInt8Array[i] = raw.charCodeAt(i);
                }
                return new Blob([uInt8Array], {type: contentType});
            },
            downloadFile(fileName, content) {
                let aLink = document.createElement('a');
                let blob = this.base64ToBlob(content); //new Blob([content]);
                let evt = document.createEvent("HTMLEvents");
                evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
                aLink.download = fileName;
                aLink.href = URL.createObjectURL(blob);
                aLink.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));//兼容火狐
            },
            downImage() {
                // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
                const opts = {
                    useCORS: true,
                    backgroundColor: null
                }
                html2canvas(this.$refs.imageTofile, opts).then((canvas) => {
                    let url = canvas.toDataURL('image/png');
                    this.downloadFile('电子收据.png', url);
                })
            },
            openReceipt(val) {
                this.selMsg = val;
                this.selMsg.daxie = utilsDX(this.selMsg.pay_price)

                // this.receiptVisible = true;

                let proofMsg = {
                    selMsg: this.selMsg,
                    showMsg: this.showMsg,
                    sort:'paid',
                    fileName:'电子收据.png',
                }
                console.log(proofMsg);
                let url = window.location.origin + window.location.pathname + '#/proof?proofMsg=' + encodeURIComponent(JSON.stringify(proofMsg))
                window.open(url)
            },
            openReceipt2(val) {
                this.selMsg = val;
                this.selMsg.daxie = utilsDX(this.selMsg.refund_price)

                // this.receiptVisible = true;

                let proofMsg = {
                    selMsg: this.selMsg ,
                    showMsg: this.showMsg,
                    sort:'refund',
                    fileName:'退款收据.png',
                }
                console.log(proofMsg);
                let url = window.location.origin + window.location.pathname + '#/proof?proofMsg=' + encodeURIComponent(JSON.stringify(proofMsg))
                window.open(url)
            },
            exportExcel() {
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    //数据表格
                    let exportList = [];
                    var aoa = [
                        ['支付宝账号', '支付宝账号姓名', '转账金额', '转账人', '转账时间', '转账状态', '备注'],
                    ];
                    let dayinName = ['receiverId', 'receiverName', 'transAmount', 'operaterName', 'fuTransDate', 'statusName', 'remark']
                    exportList.forEach(item => {
                        let a = []
                        dayinName.forEach(item2 => {
                            a.push(item[item2])
                        })
                        aoa.push(a)
                    })
                    //创建book
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.aoa_to_sheet(aoa);
                    //设置列宽
                    ws['!cols'] = [
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                    ];
                    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
                    //输出
                    let exportName = "转账记录.xlsx"
                    XLSX.writeFile(wb, exportName);
                }).catch(() => {
                })
            },
            saveRefund() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        let url = '/admin/v1/refund';
                        let data = {
                            order_id: this.selMsg.id,
                            card_number: this.selMsg.card_number,
                            user_name: this.selMsg.user_name,
                            project_name: this.selMsg.project_name,
                            project_id: this.selMsg.project_id,
                            class_name: this.selMsg.class_name,
                            pay_price: this.selMsg.pay_price,
                            refund_price: this.ruleForm.refund_price,
                            refund_type: this.ruleForm.refund_type,
                            remarks: this.ruleForm.remarks,
                        }
                        let loadingInstance = ElLoading.service()
                        businessPost(url, data).then(res => {
                            setTimeout(()=>{
                                loadingInstance.close()
                                if (res.data.status == 200) {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'success'
                                    });
                                    this.dialogVisible = false;
                                    this.getTableData();
                                }
                                else {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'warning'
                                    });
                                }
                            },300)

                        })
                    }
                })
            },
            openCall(sort, val) {
                let data = {};
                let msg = '';
                if (sort == 1) {
                    msg = '此操作将对 ' + val.user_name + ' 进行催缴';
                    data.ids = val.id;
                }
                else if (sort == 2) {
                    if (this.tableDataSel.length > 0) {
                        msg = '此操作将对 ' + this.tableDataSel.length + ' 名学生进行催缴';
                        let idsList = []
                        this.tableDataSel.forEach(item => {
                            idsList.push(item.id)
                        })
                        data.ids = idsList.join(',')
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: '请先选择被催缴的学生',
                            type: 'warning'
                        });
                        return
                    }
                }
                else if (sort == 3) {
                    msg = '此操作将对所有学生进行催缴'
                    data.ids = 'all'
                }
                this.$confirm(msg + ', 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let url = '/admin/v1/wechat/subscribe/send';
                    businessPost(url, data).then(res => {
                        if (res.data.status == 200) {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'success'
                            });
                            this.activeName = '3'
                            this.getTableData3();
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                        }
                    })
                }).catch(() => {

                })
            },
            openRefund(val) {
                this.selMsg = val;
                this.ruleForm.refund_type = '';
                this.ruleForm.refund_price = this.selMsg.pay_price*1;
                this.ruleForm.remarks = '';
                this.dialogVisible = true;
            },
            cleanAll() {
                this.searchForm.org_id = '';
                this.searchForm.project_id = '';
                this.searchForm.pay_type = '';
                this.searchForm.pay_status = '';
                this.searchForm.refund_status = '';
                this.searchForm.card_number = '';
                this.searchForm.user_name = '';
                this.searchForm.transaction_id = '';
                this.searchForm.pay_time = [];
                this.searchForm.refund_time = [];
                this.search();
            },
            search() {
                this.$refs.publicPageUserStudent.currentPage = 1;
                if(this.activeName === '1') {
                    this.getTableData()
                } else if(this.activeName === '2') {
                    this.getTableData2()
                } else if(this.activeName === '3') {
                    this.getTableData3()
                }
            },
            getSelData(val) {
                this.tableDataSel = JSON.parse(JSON.stringify(val));
            },
            handleClick(tab, event) {
                console.log(tab, event);
                this.tableData = [];
                this.$refs.publicPageUserStudent.currentPage = 1;
                this.cleanAll()
                // if(this.activeName === '1') {
                //     this.getTableData()
                // } else if(this.activeName === '2') {
                //     this.getTableData2()
                // } else if(this.activeName === '3') {
                //     this.getTableData3()
                // }
            },
            getTableData() {
                let url = `/admin/v1/order?sort=${this.sort}&page=` + this.$refs.publicPageUserStudent.currentPage + '&page_pre=' + this.$refs.publicPageUserStudent.pageSize;
                for (let k in this.searchForm) {
                    if (this.searchForm[k]) {
                        if (k == 'org_id') {
                            url = url + '&' + k + '=' + this.searchForm[k][this.searchForm[k].length - 1];
                        }
                        else if (k == 'pay_time') {
                            if (this.searchForm[k][0] && this.searchForm[k][1]) {
                                url = url + '&' + k + '=' + this.searchForm[k][0] + ' 00:00:00 - ' + this.searchForm[k][1] + ' 23:59:59';
                            }
                        }
                        else {
                            url = url + '&' + k + '=' + this.searchForm[k];
                        }
                    }
                }
                this.tableLoading = true;
                businessGet(url).then(res => {
                    this.tableLoading = false;
                    if (res.data.status == 200) {
                        this.total = res.data.data.count;
                        let tableData = res.data.data.list;
                        tableData.forEach(item => {
                            item.pay_type_name = Global.Global_pay_type_obj[item.pay_type.toString()];
                            item.pay_status_name = Global.Global_pay_status_obj[item.pay_status];
                        })
                        this.tableData = tableData;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            getTableData2() {
                let url = '/admin/v1/refund?page=' + this.$refs.publicPageUserStudent.currentPage + '&page_pre=' + this.$refs.publicPageUserStudent.pageSize;
                for (let k in this.searchForm) {
                    if (this.searchForm[k]) {
                        if (k == 'org_id') {
                            url = url + '&' + k + '=' + this.searchForm[k][this.searchForm[k].length - 1];
                        }
                        else if (k == 'refund_time') {
                            if (this.searchForm[k][0] && this.searchForm[k][1]) {
                                url = url + '&' + k + '=' + this.searchForm[k][0] + ' 00:00:00 - ' + this.searchForm[k][1] + ' 23:59:59';
                            }
                        }
                        else {
                            url = url + '&' + k + '=' + this.searchForm[k];
                        }
                    }
                }
                this.tableLoading = true;
                businessGet(url).then(res => {
                    this.tableLoading = false;
                    if (res.data.status == 200) {
                        this.total = res.data.data.count;
                        let tableData = res.data.data.list;
                        tableData.forEach(item => {
                            item.refund_type_name = Global.Global_refund_type_obj[item.refund_type];
                            item.refund_status_name = Global.Global_refund_status_obj[item.refund_status];
                        })
                        this.tableData = tableData;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            getTableData3() {
                let url = '/admin/v1/arrears?page=' + this.$refs.publicPageUserStudent.currentPage + '&page_pre=' + this.$refs.publicPageUserStudent.pageSize;
                for (let k in this.searchForm) {
                    if (this.searchForm[k]) {
                        if (k == 'org_id') {
                            url = url + '&' + k + '=' + this.searchForm[k][this.searchForm[k].length - 1];
                        }
                        else {
                            url = url + '&' + k + '=' + this.searchForm[k];
                        }
                    }
                }
                this.tableLoading = true;
                businessGet(url).then(res => {
                    this.tableLoading = false;
                    if (res.data.status == 200) {
                        this.total = res.data.data.count;
                        let tableData = res.data.data.list;
                        // tableData.forEach(item => {
                        //     item.statusName = this.statusObj[item.status];
                        //     item.typeName = this.typeObj[item.type]
                        // })
                        this.tableData = tableData;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            getMsg() {
                let url = '/admin/v1/base_config/' + this.userInfo.school_code;
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        if (res.data.data.receipt_seal) {
                            this.showMsg.receipt_seal = res.data.data.receipt_seal;
                        }
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            }
        }
    }
</script>

<style scoped>

    @import "../../assets/css/base.css";

    .receiptBT {
        border-top: 1px solid #303133;
    }

    .receiptBL {
        border-left: 1px solid #303133;
    }

    .receiptBR {
        border-right: 1px solid #303133;
    }

    .receiptBB {
        border-Bottom: 1px solid #303133;
    }

    .receipt {
        width: 1440px;
        height: 712px;
        box-sizing: border-box;
        padding: 20px 10px;
        border: 1px solid #eee;
        margin: 0 auto;
        position: relative;
        background-color: #fff;
    }

    .receiptTitle {
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        color: #303133;
    }

    .receiptSubtitle {
        width: 100%;
        height: 34px;
        line-height: 34px;
        font-size: 12px;
        color: #303133;
    }

    .receiptSubtitleLeft {
        height: 100%;
        float: left;
    }

    .receiptSubtitleRight {
        height: 100%;
        float: right;
    }

    .receiptMain {
        width: 100%;
        box-sizing: border-box;
    }

    .receiptMainLeft {
        width: 50%;
        box-sizing: border-box;
        float: left;
    }

    .receiptMainLeftOne {
        width: 100%;
        height: 58px;
        box-sizing: border-box;
    }

    .receiptMainLeftOneIn {
        width: 50%;
        height: 100%;
        box-sizing: border-box;
        float: left;
    }

    .receiptMainLeftMsg {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    .receiptMainLeftMsgTop {
        width: 100%;
        height: 50%;
        box-sizing: border-box;
        padding: 8px 10px 0 10px;
        color: #606266;
        font-size: 12px;
        text-align: left;
    }

    .receiptMainLeftMsgBottom {
        width: 100%;
        height: 50%;
        box-sizing: border-box;
        padding: 0 10px;
        color: #000000;
        font-size: 12px;
        text-align: left;
        font-weight: bold;
    }

    .receiptMainLeftOne2 {
        width: 100%;
        height: 116px;
        box-sizing: border-box;
    }

    .receiptMainLeftOne3 {
        width: 100%;
        height: 40px;
        box-sizing: border-box;
        line-height: 40px;
        text-align: left;
        color: #606266;
        padding: 0 16px;
        font-size: 14px;
    }

    .receiptMainZhang {
        width: 200px;
        height: 200px;
        box-sizing: border-box;
        object-fit: contain;
        position: absolute;
        top: 234px;
        left: 68px;
    }

</style>
